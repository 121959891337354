import { yupResolver } from '@hookform/resolvers';
import { Button, Col, Form, Row, Select, Typography } from '@objectedge/pecai-storefront-ds';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Breakpoint, useBreakpoint } from '~/utils/display';
import Yup from '~/utils/yup';
import { formStateValuesProps, optionsProps } from '../ModalCustomerRegistrationForm';
import styles from '../ModalCustomerRegistrationForm.module.scss';

interface ShopDetailsFormProps {
  handleNextFormStep: () => void;
  handlePreviousFormStep: () => void;
  formStateValues: formStateValuesProps;
  setFormStateValues: React.Dispatch<React.SetStateAction<formStateValuesProps>>;
}

const optionsAverageClients: optionsProps[] = [
  { label: '0 a 3 carros/dia', value: 0 },
  { label: '4 a 6 carros/dia', value: 4 },
  { label: '7 a 15 carros/dia', value: 7 },
  { label: '+15 carros/dia', value: 15 },
];

const optionsEmployees: optionsProps[] = [
  { label: '0 a 3 funcionários', value: 0 },
  { label: '4 a 6 funcionários', value: 4 },
  { label: '7 a 15 funcionários', value: 7 },
  { label: '+15 funcionários', value: 15 },
];

const optionsElevators: optionsProps[] = [
  { label: '0 elevadores', value: 0 },
  { label: '1 a 3 elevadores', value: 1 },
  { label: '4 a 6 elevadores', value: 4 },
  { label: '+6 elevadores', value: 6 },
];

const schema = Yup.object().shape({
  employees: Yup.object().oneSelected('Campo é obrigatório'),
  elevators: Yup.object().oneSelected('Campo é obrigatório'),
  averageClients: Yup.object().oneSelected('Campo é obrigatório'),
});

const ShopDetailsForm: React.FC<ShopDetailsFormProps> = ({
  handleNextFormStep,
  handlePreviousFormStep,
  formStateValues,
  setFormStateValues,
}) => {
  const isMiniMobileView = useBreakpoint({ max: Breakpoint.xs });
  const isMobileView = useBreakpoint({ max: Breakpoint.md });

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: formStateValues, resolver: yupResolver(schema) });

  const saveData = (values) => {
    const { itemsAvailable, ...formData } = values;
    setFormStateValues({ ...formStateValues, ...formData });
    handleNextFormStep();
  };

  return (
    <Form onSubmit={handleSubmit(saveData)} className={styles.formContent}>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="employees">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Número de Funcionários<span className="text-primary ml-1">*</span>{' '}
            </Typography>
          </label>
          <Controller
            name="employees"
            control={control}
            as={<Select />}
            options={optionsEmployees}
            className={styles.formSelect}
            defaultValue={formStateValues.employees}
            onChange={(_, newValue) => setValue('employees', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Selecione o número de funcionários
              </Typography>
            }
            fixLabel={!!formStateValues.employees}
            status={errors.employees ? 'danger' : 'default'}
            helperText={errors.employees?.message ?? ''}
          />
        </Col>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="elevators">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Número de Elevadores<span className="text-primary ml-1">*</span>{' '}
            </Typography>
          </label>
          <Controller
            name="elevators"
            control={control}
            as={<Select />}
            options={optionsElevators}
            className={styles.formSelect}
            defaultValue={formStateValues.elevators}
            onChange={(_, newValue) => setValue('elevators', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Selecione o número de elevadores
              </Typography>
            }
            fixLabel={!!formStateValues.elevators}
            status={errors.elevators ? 'danger' : 'default'}
            helperText={errors.elevators?.message ?? ''}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label className={styles.inputLabel} htmlFor="averageClients">
            <Typography
              style={{ marginBottom: '13px' }}
              variant={!isMobileView ? 'h6' : isMiniMobileView ? 'body-small' : 'body-regular'}
              color="primary-900"
            >
              Carros atendidos por dia<span className="text-primary ml-1">*</span>
            </Typography>
          </label>
          <Controller
            name="averageClients"
            control={control}
            as={<Select />}
            options={optionsAverageClients}
            className={styles.formSelect}
            defaultValue={formStateValues.averageClients}
            onChange={(_, newValue) => setValue('averageClients', newValue)}
            label={
              <Typography
                style={{ marginTop: '6px', fontFamily: 'Roboto' }}
                variant="caption-1"
                color="gray-100"
                weight="normal"
              >
                Quantidade de carros
              </Typography>
            }
            fixLabel={!!formStateValues.averageClients}
            status={errors.averageClients ? 'danger' : 'default'}
            helperText={errors.averageClients?.message ?? ''}
          />
        </Col>
      </Row>

      <div className={styles.buttonContainer}>
        <Button onClick={handlePreviousFormStep} size="sm" color="secondary" outline>
          Voltar
        </Button>
        <Button type="submit" size="sm">
          Próximo passo
        </Button>
      </div>
    </Form>
  );
};

export default ShopDetailsForm;
